import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../component/Login";
import Dashboard from "../component/Dashboard";
import CommonLayout from "../common/CommonLayout";
import UserManagement from "../component/UserManagement";
import Creators from "../component/Creaters";
import Brands from "../component/Brands";
import LeaderBoardManagement from "../component/LeaderBoardManagement";


const Routing = () => {
  return (
    <Router>
      <Routes>
      <Route
          path="/"
          element={<Login />}
        ></Route>
        <Route
          path="/dashboard"
          element={<CommonLayout>{<Dashboard />}</CommonLayout>}
        ></Route>
            <Route
          path="/creators"
          element={<CommonLayout>{<Creators />}</CommonLayout>}
        ></Route>
        <Route
          path="/brands"
          element={<CommonLayout>{<Brands />}</CommonLayout>}
        ></Route>
         <Route path="/user-management" element={<CommonLayout>{<UserManagement />}</CommonLayout>} />
         {/* <Route path="/user-management/:userType" element={<CommonLayout>{<UserManagement />}</CommonLayout>} /> */}
      <Route
          path="/leader-board-user-management"
          element={<CommonLayout>{<LeaderBoardManagement />}</CommonLayout>}
        ></Route>
      </Routes>
    </Router>
  );
};

export default Routing;
