import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import LineChart from "./LineChart";
import { apiService } from "../services/apiservices";
import { useEffect, useState } from "react";
import { config } from "../config/config";

const Dashboard = () => {
    const [usersList,setUsersList] = useState([])
    const options = [
        { value: "Nike", label: "Nike" },
        { value: "Instax Camera", label: "Instax Camera" },
        { value: "Jennie", label: "Jennie" },
        { value: "Kim-Yeelo", label: "Kim-Yeelo" },
    ];

    useEffect(()=>{
        fetchPendingList()
    },[])
    const fetchPendingList =async()=>{
        try {
            const response = await apiService.getPendingList();
            const userList = response.data.list || [];
            setUsersList(userList)
            
        } catch (error) {
            console.log("Error Fetching Users List", error);
            setUsersList([])
        }
    }

    const handleEnable = async (userId) => {
        try {
            const params = {
                userId : userId,
                isApproved: true
            }
             await apiService.changeUserStatus(params);
            setUsersList((prevList) =>
                prevList.map((user) =>
                    user._id === userId ? { ...user, isActive: true } : user
                )
            );
            
        } catch (error) {
            console.log("Failed to enable user");
        }
    };

    const handleDisable = async (userId) => {
        try {
            const params = {
                userId : userId,
                isApproved: false
            }
             await apiService.changeUserStatus(params);
            setUsersList((prevList) =>
                prevList.map((user) =>
                    user._id === userId ? { ...user, isActive: false } : user
                )
            );
        } catch (error) {
            console.log("Failed to disable user");
        }
    };

    return (
        <>
            <section className="common-area">
                <Container fluid>
                    <div className="dashboard-area">
                        <h6>Good Morning, <span>Jennie</span></h6>
                        <p>Streamline workflows, connect with partners, and drive impactful campaigns.</p>
                        <img src={require("../assets/images/dashboard-banner.png")} />
                    </div>
                    <Row className="creater-dashboard-area">
                        <Col md={3}>
                            <div className="total-creaters-area">
                                <div>
                                    <p>Total Creators</p>
                                    <h5>2,100</h5>
                                </div>
                                <img src={require("../assets/images/image1.png")} />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="total-creaters-area">
                                <div>
                                    <p>Total Brands</p>
                                    <h5>2,100</h5>
                                </div>
                                <img src={require("../assets/images/image2.png")} />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="total-creaters-area">
                                <div>
                                    <p>Pending Request</p>
                                    <h5>2,100</h5>
                                </div>
                                <img src={require("../assets/images/image3.png")} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <div className="chart-area">
                        <LineChart />
                        </div>
                       
                        </Col>
                        <Col md={6}>
                            <div className="request-listing">
                                <div className="request-listing-top">
                                    <h6>New Request Listing</h6>
                                    <Form.Group
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Select options={options} placeholder="User Type" />
                                    </Form.Group>
                                </div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>User Type</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { usersList.length>0 ? (
                                            usersList.map((user)=>(
                                        <tr key={user._id}>
                                            <td>
                                            <div className="user-type-area">
                                                <img src={`${config.imageUrl}${user.image}` || require("../assets/images/user-avtar.png")} alt="Image" />
                                                {/* {console.log(`${config.imageUrl}${user.image}`)} */}
                                                <div>
                                                <h6>{ user.role === "brand" ? user.brandName || "N/A" : `${user.firstName} ${user.lastName}` || "N/A"}</h6>
                                                <p>{user.role || "N/A"}</p>
                                                </div>
                                                </div>
                                            </td>
                                            <td>{user.createdAt ? new Date(user.createdAt).toLocaleDateString() : "N/A"}</td>
                                            {/* <td>{user.isActive ? "Active" : "Inactive"}</td> */}
                                            <td>
                                            <Button type="button" variant="unset" className="approve-btn" onClick={() => handleEnable(user._id)} disabled={user.isActive}> Approve </Button>
                                            <Button type="button" variant="unset" className="reject-btn" onClick={() => handleDisable(user._id)} disabled={!user.isActive}> Reject </Button>
                                            </td>
                                        </tr>
                                            ))
                                        ):(
                                            <tr>
                                        <td colSpan="5">
                                            <div className="no-data-area">
                                                <img src={require("../assets/images/no-data.gif")} />
                                                <h6> No users found.</h6>
                                            </div>
                                        </td>
                                    </tr>
                                        )}
                                        
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};
export default Dashboard;