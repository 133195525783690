import Header from "./Header";
import Sidebar from "./Sidebar";

const CommonLayout = (props) => {
  return (
    <>
      <Sidebar />
      <Header />
      {props.children}
    </>
  );
};

export default CommonLayout;
