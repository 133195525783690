import { Button, Container, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../services/apiservices";
import { useEffect, useState } from "react";
import { config } from "../config/config";
import { useNavigate, useParams } from "react-router-dom";
const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const { userType } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const navigate = useNavigate()

    const options = [
        { value: "Creators", label: "Creators" },
        { value: "Brands", label: "Brand" },
    ];

    useEffect(() => {
        
        fetchUsers(userType,page,searchTerm);
    }, [searchTerm,userType,page]);

    const fetchUsers = async (userType,page,searchKey) => {
        try {
            const response = await apiService.getUser(userType || "",page,searchKey);
            const userList = response.data.list || [];
            setUsers(userList);
        } catch (error) {
            console.error("Error fetching users:", error);
            setUsers([]);
        }
    };

   const handleSelectionChange = (selectedOption)=>{
        if (selectedOption) {
            navigate(`/${selectedOption.value}`);
        }
    }
    return (
        <>
            <section className="common-area">
                <Container fluid>
                    <div className="head-area">
                        <h3>User Management</h3>
                        <div className="search-area">
                            <div className="head-search">
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className=" mr-sm-2"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.58341 17.5C13.9557 17.5 17.5001 13.9556 17.5001 9.58332C17.5001 5.21107 13.9557 1.66666 9.58341 1.66666C5.21116 1.66666 1.66675 5.21107 1.66675 9.58332C1.66675 13.9556 5.21116 17.5 9.58341 17.5Z" stroke="#1F253C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3334 18.3333L16.6667 16.6667" stroke="#1F253C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <Form.Group
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Sort By</Form.Label>
                                <Select options={options} placeholder="Status"  onChange={handleSelectionChange}/>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="user-maagement-table">
                        <Table>
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>Type</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 ? (
                                    users.map((user, index) => (
                                        <tr key={index}>
                                            <td>
                                                <img src={user.role === "brand" ? user.image? `${config.imageUrl}${user.image}` : require("../assets/images/user-avtar.png") : `${config.imageUrl}${user.image}` || require("../assets/images/user-avtar.png")} alt="Image" />
                                                {user.role === "brand" ? user.brandName || "N/A" : `${user.firstName} ${user.lastName}` || "N/A"}
                                            </td>
                                            <td>{user.role || "N/A"}</td>
                                            <td>{user.email || "N/A"}</td>
                                            <td>{user.createdAt ? new Date(user.createdAt).toLocaleDateString() : "N/A"}</td>
                                            <td>
                                                <Button type="button" variant="unset" className="enable-btn">Enable</Button>
                                                <Button type="button" variant="unset" className="disable-btn">Disable</Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="no-data-area">
                                                <img src={require("../assets/images/no-data.gif")} />
                                                <h6> No users found.</h6>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default UserManagement