import { Button, Col, Container, Form, Offcanvas, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../services/apiservices";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../config/config";
const Brands = () => {
    const userType = useParams()
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [show, setShow] = useState(false);
    // const [selectedOption,setSelectedOption] = useState(null)
    const navigate = useNavigate()
    const options = [
        { value: "creators", label: "Creators" },
        { value: "brands", label: "Brands" },
    ];

    useEffect(() => {
        // const selected = options.find((option) => option.value === userType);
        // setSelectedOption(selected || null);

        fetchUsers();
    }, [searchTerm]);
    
    const fetchUsers = async () => {
        try {
            const response = await apiService.getUser(searchTerm);
            const userList = response.data.list || [];
            const filteredUsers = userList.filter((users)=>users.role === "brand")
            setUsers(filteredUsers);
        } catch (error) {
            console.error("Error fetching users:", error);
            setUsers([]);
        }
    };
    
    const handleSelectionOptions = (selectedOption)=>{
        // setSelectedOption(selectedOption)
        if(selectedOption){
            navigate(`/${selectedOption.value}`)
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <section className="common-area">
                <Container fluid>
                    <div className="head-area">
                        <h3>Brands Listing</h3>
                        <div className="search-area">
                            <div className="head-search">
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className=" mr-sm-2"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.58341 17.5C13.9557 17.5 17.5001 13.9556 17.5001 9.58332C17.5001 5.21107 13.9557 1.66666 9.58341 1.66666C5.21116 1.66666 1.66675 5.21107 1.66675 9.58332C1.66675 13.9556 5.21116 17.5 9.58341 17.5Z" stroke="#1F253C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.3334 18.3333L16.6667 16.6667" stroke="#1F253C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <Form.Group
                                controlId="exampleForm.ControlInput1"
                            >
                                <Select options={options} placeholder="Status" onChange={handleSelectionOptions}/>
                            </Form.Group>

                        </div>
                    </div>
                    <div className="user-maagement-table">
                        <Table>
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>Type</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 ? (
                                    users.map((user, index) => (
                                <tr onClick={handleShow}
                                 key={index}
                                >
                                    <td>
                                        <img src={`${config.imageUrl}${user.image}` || require("../assets/images/user-avtar.png")} alt="User" />
                                        {user.brandName|| "N/A"}
                                    </td>
                                    <td>{user.role || "N/A"}</td>
                                    <td>{user.email || "N/A"}</td>
                                    <td>{user.createdAt ? new Date(user.createdAt).toLocaleDateString() : "N/A"}</td>
                                    <td>
                                        <Button type="button" variant="unset" className="enable-btn">Enable</Button>
                                        <Button type="button" variant="unset" className="disable-btn">Disable</Button>
                                    </td>
                                </tr>
                                     ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="no-data-area">
                                                <img src={require("../assets/images/no-data.gif")} />
                                                <h6> No users found.</h6>
                                            </div>
                                        </td>
                                    </tr>
                                )} 
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </section>

            {/* offcanvas */}

            <Offcanvas show={show} onHide={handleClose} placement="end" className="creator-offcanvas">
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Social Links</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Most active platform</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Are you actively posting videos as an TikTok shop affiliate? </Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>How many brands are you looking to work with monthly?</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>How many affiliates / creators are you looking to get</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Do you have a affiliate program setup on Instagram or TikTok ?</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
};
export default Brands