import axios from 'axios';
import { formDataHeader, formDataHeaderToken, jsonHeaders, jsonHeadersToken } from '../helpers';
import { config } from '../config/config';


var configFormDataHeaders = {
    headers: formDataHeader()
}

var formDataToken = {
    headers: formDataHeaderToken()
}

var configJsonHeaders = {
    headers: jsonHeaders()
}

var jsonHeadersTokenHeader = {
    headers: jsonHeadersToken()
}


export const apiService = {
    adminLogin,
    getUser,
    getPendingList,
    changeUserStatus,
    getLeaderBoardUsers
};


function adminLogin (params){
    return axios.post(`${config.apiUrl}/admin/admin-login`,params)
}

function getUser (userType,page=0,searchKey){
    return axios.get(`${config.apiUrl}/admin/getUsers?page=${page}&role=${userType}&searchKey=${searchKey}`,jsonHeadersTokenHeader)
}

function getPendingList (){
    return axios.get(`${config.apiUrl}/admin/get-pending-list`,jsonHeadersTokenHeader)
}

function changeUserStatus(params){
    return axios.post(`${config.apiUrl}/admin/approve-reject`,params,jsonHeadersTokenHeader)
}

function getLeaderBoardUsers (){
    return axios.get(`${config.apiUrl}/admin/get-landing-page-list`,jsonHeadersTokenHeader)
}